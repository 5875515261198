export function getUrlFromRichtext(richtext: string = '') {
  const url = richtext.match(/href="([^"]*)/)?.[1]
  return url ?? '/'
}

export function getTargetFromRichtext(richtext: string = '') {
  const target = richtext.match(/target="([^"]*)/)?.[1]
  return target ?? undefined
}

export function getInnerTextFromRichtext(richtext: string = '') {
  return richtext.replace(/<(?:.|\n)*?>/gm, '')
}
